import styled, { css } from 'styled-components';

import { Subsite } from '../../../types';

import {
	xsBodyLight,
	smBodyLight,
	smBodyRegular,
	mdBodyRegular,
} from '../Typography/index.styled';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing, margins, gutters } from '../../../styles/settings/spacing';
import { above, between, hover } from '../../../styles/tools/media';

type FooterStyleProps = {
	fullWidth?: boolean;
	groupTheme?: Subsite;
};

const Footer = styled.footer<FooterStyleProps>`
	${props => {
		if (props.groupTheme === Subsite.BruntwoodSciTech) {
			return css`
				--footerBgColor: #161617;
				--footerTextColor: var(--white);
				--footerLinkColor: var(--white);
				--footerLinkHoverColor: var(--white);
				--footerHrColor: var(--white);
			`;
		}
		if (props.groupTheme === Subsite.BruntwoodWorks) {
			return css`
				--footerBgColor: #ffaa2d;
				--footerTextColor: var(--black);
				--footerLinkColor: var(--black);
				--footerLinkHoverColor: var(--black);
				--footerHrColor: var(--white);
			`;
		}
		return css`
			--footerBgColor: #161617;
			--footerTextColor: var(--white);
			--footerLinkColor: var(--white);
			--footerLinkHoverColor: var(--primaryAccent);
			--footerHrColor: var(--primaryAccent);
		`;
	}}

	position: relative;
	background-color: var(--footerBgColor);
	color: var(--footerTextColor);
	overflow: hidden;
`;

const FooterRipple = styled.div`
	position: absolute;
	bottom: -30px;
	left: 0;
	width: 100%;
	max-width: 109px;

	${above(
		breakpoints[768],
		() => css`
			max-width: 20vw;
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			max-width: calc((100vw / 10) + 72px);
			height: 100%;
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			max-width: 240px;
			height: auto;
		`
	)}
`;

const FooterInner = styled.div<FooterStyleProps>`
	padding: ${spacing[48]} ${margins[16]};

	${above(
		breakpoints[600],
		() => css`
			padding-right: ${margins[24]};
			padding-left: ${margins[24]};
		`
	)}

	${above(
		breakpoints[768],
		() => css<FooterStyleProps>`
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			${props => !props.fullWidth && 'padding-left: 22vw'};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			display: grid;
			align-items: end;
			grid-column-gap: ${gutters[24]};
			grid-template-columns: repeat(12, 1fr);
			padding: ${spacing[64]} ${margins[48]};
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			padding-right: ${margins[80]};
			padding-left: ${margins[80]};
		`
	)}

	${above(
		breakpoints[1920],
		() => css`
			grid-gap: ${gutters[32]};
			padding-right: ${margins[120]};
			padding-left: ${margins[120]};
		`
	)}
`;

const FooterContact = styled.div<FooterStyleProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: ${gap[40]};

	${above(
		breakpoints[768],
		() => css`
			width: 58%;
			display: block;
			margin-bottom: ${gap[0]};
		`
	)}

	${above(
		breakpoints[960],
		() => css<FooterStyleProps>`
			width: auto;
			grid-column: ${props => (props.fullWidth ? 'span 6' : '3 / span 5')};
		`
	)}
`;

const FooterContactIconLinks = styled.div`
	margin-top: ${gap[16]};
`;

const FooterTitle = styled(mdBodyRegular)`
	margin-bottom: ${gap[16]};
`;

const FooterAddress = styled(smBodyLight)`
	font-style: normal;
`;

const FooterContactItem = styled(smBodyRegular)`
	display: inline-flex;
	align-items: center;
	margin-bottom: ${gap[24]};

	&:last-of-type {
		margin-bottom: ${gap[0]};
	}

	svg {
		margin-right: ${gap[8]};
	}

	${above(
		breakpoints[768],
		() => css<FooterStyleProps>`
			margin-right: ${gap[24]};
			margin-bottom: ${gap[0]};
			grid-column: ${props => (props.fullWidth ? 'span 6' : '3 / span 5')};

			&:last-of-type {
				margin-right: ${gap[0]};
			}
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			margin-right: ${gap[56]};
		`
	)}
`;

const FooterContactLink = styled.a`
	color: var(--footerLinkColor);
	text-decoration: none;

	${hover(
		() => css`
			position: relative;
			transition: color 0.3s ease-in-out;

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 2px;
				background-color: var(--primaryAccent);
				transition: width 0.3s ease-in-out;
			}

			&:hover,
			&:active,
			&:focus {
				color: var(--footerLinkHoverColor);

				&::before {
					width: 100%;
				}
			}
		`
	)}
`;

const FooterBottom = styled.div<FooterStyleProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: ${gap[40]};

	${props =>
		props.fullWidth
			? css`
					align-items: flex-start;
					text-align: left;
					li:first-child {
						margin-left: 0;
					}
			  `
			: css`
					align-items: flex-end;
					text-align: right;
			  `}


	${above(
		breakpoints[768],
		() => css`
			width: 38.1%;
			margin-top: ${gap[0]};
		`
	)}

	${above(
		breakpoints[960],
		() => css<FooterStyleProps>`
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
			width: auto;
			grid-column: ${props => (props.fullWidth ? 'span 6' : '8 / -1')};
		`
	)}
`;

const FooterUl = styled.ul`
	position: relative;
	column-count: 2;
	column-gap: ${gap[24]};
	list-style-type: none;
	margin: 0;
	padding: 0;

	${above(
		breakpoints[768],
		() => css`
			border-bottom: none;
			column-count: 3;
		`
	)}
`;

const FooterHr = styled.hr`
	margin: ${gap[16]} 0;
	height: 1px;
	background-color: var(--footerHrColor);
	border: none;
	width: 100%;
`;

const FooterLi = styled.li`
	display: inline-block;
	width: 100%;
	margin-top: ${gap[8]};
	color: var(--footerTextColor);

	a {
		display: block;
		font-weight:200!important;
		color: inherit;
		text-decoration: none;

		${hover(
			() => css`
				position: relative;
				transition: color 0.3s ease-in-out;

				&::before {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 0;
					height: 2px;
					background-color: var(--primaryAccent);
					transition: width 0.3s ease-in-out;
				}

				&:hover,
				&:active,
				&:focus {
					color: var(--footerLinkHoverColor);

					&::before {
						width: 100%;
					}
				}
			`
		)}
	}

	${between(
		breakpoints[768],
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[0]};
		`
	)}
`;

const FooterSocialUl = styled.ul`
	display: flex;
	margin-bottom: ${gap[40]};
	padding-left: ${gap[0]};
	list-style-type: none;

	${above(
		breakpoints[768],
		() => css`
			margin-top: ${gap[0]};
			margin-bottom: ${gap[32]};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[56]};
		`
	)}
`;

const FooterSocialLi = styled.li`
	margin-left: ${gap[16]};
	color: var(--footerTextColor);

	a {
		display: block;
		color: inherit;
		text-decoration: none;

		${hover(
			() => css`
				transition: color 0.3s ease-in-out;

				&:hover,
				&:active,
				&:focus {
					color: var(--primaryForeground);
				}
			`
		)}
	}

	svg {
		${above(
			breakpoints[960],
			() => css`
				width: 48px;
				height: 48px;
			`
		)}
	}
`;

const FooterCopyright = styled.div`
	max-width: 66vw;
	margin-top: ${gap[40]};

	${above(
		breakpoints[768],
		() => css`
			margin-top: ${gap[32]};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			max-width: 385px;
			margin-top: ${gap[56]};
			text-align: right;
		`
	)}
`;

const Copyright = styled(xsBodyLight)`
	margin-bottom: ${gap[12]};

	&:last-child {
		margin-bottom: ${gap[0]};
	}
`;

export default {
	Footer,
	FooterRipple,
	FooterInner,
	FooterContact,
	FooterContactIconLinks,
	FooterTitle,
	FooterAddress,
	FooterContactItem,
	FooterContactLink,
	FooterBottom,
	FooterUl,
	FooterLi,
	FooterHr,
	FooterSocialUl,
	FooterSocialLi,
	FooterCopyright,
	Copyright,
};
