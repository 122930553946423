import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { FooterTypes, Subsite } from '@/types';

import { pushEvent } from '@/lib/tracking';
import Twitter from '@/svgs/Social/Twitter';
import Facebook from '@/svgs/Social/Facebook';
import LinkedIn from '@/svgs/Social/LinkedIn';
import Instagram from '@/svgs/Social/Instagram';
// import Ripple from '@/svgs/Ripple';
import Phone from '@/svgs/UI/Phone';
import Pointer from '@/svgs/UI/Pointer';
import BruntwoodLogoSVG from '@/svgs/BruntwoodLogo';
import Link from '../Link';
import LogoLink from '../LogoLink';

import Styled from './index.styled';

const FooterMain = props => {
	const theme = useContext(ThemeContext);
	const { address, links }: FooterTypes = props;
	const currentYear = new Date().getFullYear();
	// const rippleColors = {
	// 	base: theme?.rippleBase,
	// 	tint: theme?.rippleTint,
	// };

	return (
		<Styled.Footer as="footer" groupTheme={Subsite.BruntwoodGroup}>
			{/* <Styled.FooterRipple aria-hidden="true">
				<Ripple colors={rippleColors} />
			</Styled.FooterRipple> */}

			<Styled.FooterInner fullWidth>
				<Styled.FooterContact fullWidth>
					<Styled.FooterTitle as="h3">Address</Styled.FooterTitle>

					<Styled.FooterAddress as="address">{address}</Styled.FooterAddress>

					<Styled.FooterContactIconLinks>
						<Styled.FooterContactItem>
							<Pointer fill={theme?.primaryAccent} />
							<Styled.FooterContactLink
								href="https://goo.gl/maps/1vHohSkRbnwKhYku6"
								data-ga-category="component - footer"
								data-ga-action="get directions"
								onClick={pushEvent}
							>
								Get directions
							</Styled.FooterContactLink>
						</Styled.FooterContactItem>

						<Styled.FooterContactItem>
							<Phone fill={theme?.primaryAccent} />
							<Styled.FooterContactLink
								href="tel:08007310300"
								className="rulertel"
								onClick={pushEvent}
							>
								0800 731 0300
							</Styled.FooterContactLink>
						</Styled.FooterContactItem>
					</Styled.FooterContactIconLinks>

					<Styled.FooterHr />

					<Styled.FooterUl>
						{links.map((link, index) => (
							<Styled.FooterLi key={`${link.title}--${index}`}>
								<Link
									url={link.url}
									title={link.title}
									dataGACategory={link.dataGACategory}
									dataGAAction={link.dataGAAction}
								/>
							</Styled.FooterLi>
						))}
					</Styled.FooterUl>
				</Styled.FooterContact>

				<Styled.FooterBottom>
					<Styled.FooterSocialUl>
						<Styled.FooterSocialLi>
							<a
								href="https://www.twitter.com/bruntwood_uk"
								aria-label="Follow us on Twitter"
								target="_blank"
								data-ga-category="component - footer"
								data-ga-action="Twitter"
								onClick={pushEvent}
								rel="noreferrer"
							>
								<Twitter />
							</a>
						</Styled.FooterSocialLi>
						<Styled.FooterSocialLi>
							<a
								href="https://www.facebook.com/bruntwoodUK"
								aria-label="Like our Facebook page"
								target="_blank"
								data-ga-category="component - footer"
								data-ga-action="Facebook"
								onClick={pushEvent}
								rel="noreferrer"
							>
								<Facebook />
							</a>
						</Styled.FooterSocialLi>
						<Styled.FooterSocialLi>
							<a
								href="https://www.linkedin.com/company/bruntwood"
								aria-label="Find us on LinkedIn"
								target="_blank"
								data-ga-category="component - footer"
								data-ga-action="LinkedIn"
								onClick={pushEvent}
								rel="noreferrer"
							>
								<LinkedIn />
							</a>
						</Styled.FooterSocialLi>
						<Styled.FooterSocialLi>
							<a
								href="https://www.instagram.com/bruntwood/"
								aria-label="Follow us on Instagram"
								target="_blank"
								data-ga-category="component - footer"
								data-ga-action="Instagram"
								onClick={pushEvent}
								rel="noreferrer"
							>
								<Instagram />
							</a>
						</Styled.FooterSocialLi>
					</Styled.FooterSocialUl>

					<LogoLink
						url="/"
						dataGACategory="component - footer"
						dataGAAction="Bruntwood logo"
						dataGALabel="Bruntwood logo"
						footer
					>
						<BruntwoodLogoSVG fill={theme?.white} />
					</LogoLink>

					<Styled.FooterCopyright>
						<Styled.Copyright>
							Copyright &copy; {currentYear} Bruntwood
						</Styled.Copyright>
						<Styled.Copyright>
							Bruntwood Limited, a company registered in England and Wales,
							registration number 06017744
						</Styled.Copyright>
					</Styled.FooterCopyright>
				</Styled.FooterBottom>
			</Styled.FooterInner>
		</Styled.Footer>
	);
};

export default FooterMain;
